
.section-heading{
    margin: 0 ;
}

.performance-list-item{
    display: flex;
    justify-content: space-between;
}

.performance-list-item p{
    margin-top: 0;
    margin-bottom: 5px;
}