@font-face {
  font-family: "BarlowBlack";
  src: local("BarlowBlack"),
  url("./fonts/Barlow/Barlow-Black.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BarlowBold";
  src: local("BarlowBold"),
  url("./fonts/Barlow/Barlow-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BarlowRegular";
  src: local("BarlowRegular"),
  url("./fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "VagRoundedBold";
  src: local("VagRoundedBold"),
  url("./fonts/VAG Rounded/VAG_Rundschrift.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "VagRoundedRegular";
  src: local("VagRoundedRegular"),
  url("./fonts/VAG Rounded/VAG_Rounded_Light.ttf") format("truetype");
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  font-family: 'VagRoundedRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
