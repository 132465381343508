html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

/* new */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
  text-align: center;
  /*height: 300px;*/
}

#logoHeaderWhite .st1 {
  fill: white;
}
/*#logoHeader .st1 {*/
/*  !*fill: #00374e;*!*/
/*}*/







#logoArbeitgeber1:not(.stickyButtonClick) #leftDingens,
#blauweiß #leftDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoArbeitgeber1:not(.stickyButtonClick):hover #leftDingens,
#blauweiß:hover #leftDingens {
  transform: translateY(54%);
}

#logoArbeitgeber1:not(.stickyButtonClick) #rightDingens,
#blauweiß #rightDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoArbeitgeber1:not(.stickyButtonClick):hover #rightDingens,
#blauweiß:hover #rightDingens {
  transform: translateY(-54%);
}


#logoArbeitgeber1 .stickyButtonClick #leftDingens {
  transform: translateY(54%);
}

#logoArbeitgeber1 .stickyButtonClick #rightDingens {
  transform: translateY(-54%);
}

@keyframes moveDown{
  0% {
    transform:translateY(0);
  }
  50% {
    transform:translateY(54%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveUp{
  0% {
    transform:translateY(0);
  }
  50% {
    transform:translateY(-54%);
  }
  100% {
    transform:translateY(0);
  }
}

@keyframes rotateCustom{
  0% {
    transform:rotate(0);
  }
  50% {
    transform:rotate(180deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

#testLogo {
  animation:rotateCustom 4s infinite;
}

#testLogo #leftDingens {
  animation:moveDown 1s infinite;
}

#testLogo #rightDingens {
  animation:moveUp 1s infinite;
}






#logoHeader #leftDingens,
#logoHeaderWhite #leftDingens,
#logoFooter #leftDingens {
  transition: all 0.3s ease;
  transform: translateY(-83%);
}

#logoHeader:hover #leftDingens,
#logoHeaderWhite:hover #leftDingens,
#logoFooter:hover #leftDingens {
  transform: translateY(-43%);
}

#logoHeader #rightDingens,
#logoHeaderWhite #rightDingens,
#logoFooter #rightDingens {
  transition: all 0.3s ease;
  transform: translateY(0%);
}

#logoHeader:hover #rightDingens,
#logoHeaderWhite:hover #rightDingens,
#logoFooter:hover #rightDingens {
  transform: translateY(-40%);
}

.MuiTouchRipple-root {
  /*padding: 0 !important;*/
}

.loginSvg .cls-1 {
  fill: #00374e;
}
.menuIcon {
  fill: #00374e !important;
}
.loginSvgWhite .cls-1 {
  fill: white;
}
.menuIconWhite {
  fill: white !important;
}

.menuIcon,
.menuIconWhite {
  transition: none !important;
}




#hamburger-menu {

}
#hamburger-menu.open {
  visibility: visible;
  right: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.fadeInOut {
  transition: all 0.2s ease-in-out;
}



.weg {
  transform: translateX(50%);
  opacity: 0;
}


.fadeIn {
  transform: translateX(0%) !important;
  opacity: 1;
}

.fadeOut {
  transform: translateX(-50%);
  opacity: 0;
}


.squareButtonLeft .MuiTouchRipple-child,
.squareButtonRight .MuiTouchRipple-child {
  border-radius: 0 !important;
}


@media screen and (max-width: 712px) {
  .squareButtonLeft {
    padding-bottom: 1px !important;
  }
  .squareButtonRight {
    top: 1px !important;
  }
}
@media screen and (min-width: 712px) {
  .squareButtonLeft {
    padding-bottom: 2px !important;
  }
}


.wegUp {
  transform: translateY(100%);
  opacity: 0;
}


.fadeUp {
  transform: translateY(0%) !important;
  opacity: 1;
}

.wegLeft {
  transform: translateX(20%);
  opacity: 0;
}

.fadeLeft {
  transform: translateX(-20%) !important;
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}

.wegLeft2 {
  transform: translateX(20%);
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}

.wegLeft3 {
  transform: translateX(20%);
  opacity: 0;
  content-visibility: hidden;
  display: none;
}

.fadeLeft2 {
  transform: translateX(-20%) !important;
  opacity: 0;
  /*content-visibility: hidden;*/
  /*display: none;*/
}



.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




.lds-dual-ring2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.lds-dual-ring2:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring2 1.2s linear infinite;
}
@keyframes lds-dual-ring2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




#top-container {
  list-style: none;
}

#top-container:not(.asdf) li:before {
  content: "+";
  margin-right: 12px;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.scrollToTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}


.loginSvg.yellow .cls-1 {
  fill: #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.loginSvg.blue .cls-1 {
  fill: #00374e;
  transition: all 0.2s ease-in-out 0s;
}


.loginSvgWhite.yellow .cls-1 {
  fill: #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.loginSvgWhite.blue .cls-1 {
  fill: white;
  transition: all 0.2s ease-in-out 0s;
}


.Test.yellow {
  fill: white;
  border: 2px solid white;
  transition: all 0.2s ease-in-out 0s;
}

.Test.blue {
  fill: #C7D300;
  border: 2px solid  #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.TestF.yellow {
  fill: #00374e;
  border: 2px solid #00374e;
  transition: all 0.2s ease-in-out 0s;
}

.TestF.blue {
  fill: #C7D300;
  border: 2px solid  #C7D300;
  transition: all 0.2s ease-in-out 0s;
}

.Toastify__toast {
  margin-top: 50px !important;
}

.Toastify__toast-body {
  padding: 6px 25px 6px 10px !important;
}

/*.menuItem.arbeitnehmer {*/
/*  text-align: left;*/
/*  margin-left: 15px;*/
/*}*/

.arbeitnehmer {
  color: #00374e;
}

.arbeitgeber {
  color: white;
}

.menuItemMain {
  /*transition: all 0.2s ease-in-out 0s;*/
  cursor: pointer;
  /*padding: 7px 0 4px 15px;*/
  margin: 3px 0 0 0;
}

.menuItem {
  /*transition: all 0.2s ease-in-out 0s;*/
  position: relative;
  width: max-content;
  padding-right: 15px;
}

.hover {

}

.active {
  background: rgb(138, 138, 138, 0.17);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.count {
  position: absolute;
  top: -15px;
  right: -17px;
  width: 10px;
  height: 10px;
  padding: 4px 4px 4px 4px;
  color: #00374e;
  background: #C7D300;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*box-shadow: 0 0 0 1px rgb(199 211 0 / 20%), 0 0 0 2px rgb(199 211 0 / 19%), 0 0 0 3px rgb(199 211 0 / 18%);*/
  filter: drop-shadow(0 0 1px #C7D300);
  margin: 1em;

}


.color-blue {
  color: #00374e;
}
.color-yellow {
  color: #C7D300 !important;
}


#hakenSuchen .cls-1 {
  fill: white;
}


#infoIcon,
#infoIcon:hover {
  color: #C7D300;
}

.MuiTooltip-popper {
  inset: 0 auto auto 40px !important;
}

.MuiTooltip-tooltip {
  max-width: 300px !important;
}

#erroricon,
#erroricon:hover {
  color: #FF0000;
}

.MuiTooltip-tooltip {
  padding: 6px 8px 4px !important;
  margin-top: 10px !important;
  /*text-align: center !important;*/
}

#übersicht .heart.cls-1 {
  fill: white !important;
}

.testMode:before {
  content: "\2022";
  margin-right: 5px;
}

.borderWithMarginStart:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #00374e;
  width: calc(100% - 240px);
  top: 0;
  left: 120px;
}

.borderWithMargin:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #aaa;
  width: calc(100% - 240px);
  top: 0;
  left: 120px;
}

.logoJobsucheFavorite .cls-1 {
  fill: rgba(0, 55, 78, 0.5);
}


#blauweiß .st0 {
  fill: #00374e;
}



.bouncing-loader {
  display: flex;
  justify-content: center;
  margin: 0 0 0 10px;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #00374e;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
  //transform: translateY(-6px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.15s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.15s;
}

#detailsBlock {
  text-align: center;
}

#detailsBlock summary {
  color: #C7D300;
  font-family: VagRoundedBold
}

#detailsBlock details {
  margin: 0px 19px;
  text-align: left;
}

#detailsBlock ul {
  margin: 0;
  padding-inline-start: 13px;
  color: white;
  position: relative;
}

#detailsBlock li {
  list-style-type: none;
}

#detailsBlock details > summary {
  display: inline-block;
}

#detailsBlock details[open] > summary {
  display: inline-block;
}

#detailsBlock details > summary::before {
  content: '+';
  display: inline-block;
  font-size: 16px;
  line-height: 10px;
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

#detailsBlock details[open] > summary::before {
  content: '';
  display: inline-block;
  font-size: 20px;
  line-height: 0;
  width: 8px;
  height: 0;
  border-bottom: 2px solid;
  margin-bottom: 4px;
  margin-right: 3px;
  margin-left: 2px;
}

#detailsBlock details[open] > summary #text {
  padding-top: 1px;
}

#detailsBlock .number {
  position: absolute;
  top: 0;
  font-size: 9px;
  margin-left: 2px;
}

#detailsBlock details ul {
  transition: all .2s ease-in-out;
}



@keyframes sweep {
  0%    {height: 0;}
  100%  {height: 100px;}
}

.text-input-label-blue label,
.text-input-label-blue input {
  color: #00374e !important;
}

.label-black label {
  color: black !important;
}



/*.text-input-label-blue label.MuiFormLabel-filled  {*/
/*  font-size: 14px !important;*/
/*}*/




ul#oiuz {
  display: flex;
}

ul#oiuz,
#oiuz li {
  padding-left: 20px;
}
#oiuz li span {
  position: relative;
  left: -22px;
}


#stepper .MuiStepLabel-root .MuiSvgIcon-root,
#stepper .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepper .MuiStepLabel-root .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#stepper .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
}

#stepper .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root,
#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepperMatchkorb .MuiStepLabel-root .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#stepperMatchkorb .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
}

#stepperMatchkorb .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal {
  position: relative;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(1) .MuiStepLabel-labelContainer {
  position: absolute;
  right: 0;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(3) .MuiStepLabel-labelContainer {
  position: absolute;
  right: 5px;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal:nth-child(5) .MuiStepLabel-labelContainer {
  position: absolute;
  right: -7px;
  top: 42px;
  width: unset;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label {
  color: white;
}

#stepperMatchkorb .MuiStep-root.MuiStep-horizontal.activeStep .MuiStepLabel-labelContainer .MuiStepLabel-label {
  font-family: VagRoundedBold;
}




#stepper2 .MuiStepLabel-root .MuiSvgIcon-root,
#stepper2 .MuiStepLabel-root .MuiSvgIcon-root.Mui-active {
  color: #C7D300;
}

#stepper2 #stepper .MuiSvgIcon-root {
  height: 40px;
  width: 40px;
}

#step3 .MuiTypography-root {
  margin-top: 2px;
}

#stepper2 .MuiButton-root {
  color: white;
}

#stepper2 .MuiSvgIcon-root .MuiStepIcon-text {
  font-family: VagRoundedBold, serif;
  fill: #00374e;
}

#stepper2 .MuiStepConnector-line {
  border-color: #C7D300;
  border-top-style: dashed;
  border-top-width: 2px;
}

#stepper2 .MuiStepLabel-label {
  color: white;
}

#stepper2 .MuiStepLabel-label.Mui-active {
  font-family: VagRoundedBold;
}


#testMatchkorb p {
  margin: 0;
}


#einkaufswagen .cls-1 {
  fill: white;
}

#einkaufswagen {
  background: #C7D300;
}

#matchkorb .cls-1 {
  fill: #C7D300 !important;
}

#matchkorb .cls-2 {
  fill: transparent !important;
}

#stepper2 .MuiStepLabel-root {
  position: relative;
}

#stepper2 .MuiStepLabel-labelContainer  {
  position: absolute;
  top: 45px;
  left: -10px;
}

#stepper2 .MuiStep-horizontal:nth-child(1) .MuiStepLabel-labelContainer {
  left: -14px;
}
#stepper2 .MuiStep-horizontal:nth-child(3) .MuiStepLabel-labelContainer {
  left: -10px;
}
#stepper2 .MuiStep-horizontal:nth-child(5) .MuiStepLabel-labelContainer {
  left: -8px;
}

/*.MuiFormHelperText-contained {*/
/*  min-height: 17px;*/
/*}*/





.border-blue .MuiOutlinedInput-root fieldset {
  border-color: #00374e !important;
}

#step3 .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  fill: white;
}

/*#step3 .MuiTouchRipple-rippleVisible {*/
/*  width: 60% !important;*/
/*  height: 60% !important;*/
/*}*/

.dingLabel .MuiFormControlLabel-label {
  font-size: 14px;
}


#step3 .MuiStepper-root svg:nth-child(2),
#step3 .MuiRadio-root svg:nth-child(2) {
  fill: #C7D300 !important;
}

.testClassSelect .MuiOutlinedInput-notchedOutline {
  border-radius: 25px;
  border: 2px solid #C7D300 !important;
}

.testClassSelect .MuiOutlinedInput-input {
  padding: 8.6px !important;
}

.testClassSelect label {
  top: -8px !important;
  color: #00374e !important;
  left: 6px;
  /*font-size: 12px;*/
}

.testClassSelect .MuiOutlinedInput-input {
  padding-right: 0 !important;
  text-align: left;
  padding-left: 18px !important;
  font-size: 12px;
  color: black;
}

.registrationMenu label {
  font-size: 12px !important;
}

.testClassSelect label:not(.whiteLabel) {
  top: -4px !important;
  color: black !important;
}

.testClassSelect label.whiteLabel {
  color: white !important;
}

.testClassSelect label:not(.Mui-focused) {
  top: -4px !important;
}

/*.testClassSelect label.MuiFormLabel-filled,*/
/*.testClassSelect label.Mui-focused {*/
/*  !*color: white !important;*!*/
/*  font-size: 12px !important;*/
/*}*/
.testClassSelect label {
  font-size: 12px !important;
}

.registerTest .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  background: white !important;
  border-radius: 25px;
}

.loginTest .MuiTextField-root label.MuiFormLabel-filled,
.registerTest .MuiTextField-root label.MuiFormLabel-filled {
  color: white !important;
}

.teeeest div {
  width: calc(100% - 5px);
}

#editor {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

#editor .rdw-editor-wrapper {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 400px;
}

.ck-editor__editable_inline,
.DraftEditor-root {
  min-height: 400px;
}

.fr-second-toolbar a {
  display: none !important;
}

.imageUpload .image-item {
  display: flex;
  margin: 10px 0;
}
.imageUpload .image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.imageUpload button {
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
}

.imageUpload .image-item__btn-wrapper {
  width: 100%;
}

#unternehmensprofil .MuiDataGrid-columnSeparator {
  display: none !important;
}

#unternehmensprofil .image-item,
#unternehmensprofil img {
  width: 162px;
  height: 162px;
}

#unternehmensprofil .image-item__btn-wrapper {
  margin-left: 10px;
}

#unternehmensprofil .MuiDataGrid-cell[data-field="adresse"] {
  padding-top: 5px;
  text-align: left;
  max-height: fit-content;
  display: blocK;
}

#unternehmensprofil .MuiDataGrid-cell[data-field="rechnungsadresse"] {
  /*padding-top: 5px;*/
  text-align: center;
  /*max-height: fit-content;*/
  /*display: blocK;*/
}

#unternehmensprofil .MuiCheckbox-root {
  margin: auto;
}

.testClassSelect.whiteLabel,
.testClassSelect.whiteLabel svg,
.testClassSelect.whiteLabel div,
.whiteLabel {
  color: white !important;
}
.testClassSelect {
  /*margin-top: 25px !important;*/
}

.text-input-label-white svg,
.text-input-label-white .MuiChip-label {
  color: white !important;
}

.text-input-label-white .MuiChip-filledDefault {
  border: 1px solid #C7D300;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.multiLineInputColor .MuiOutlinedInput-input {
  color: white !important;
}

.borderWhite .MuiOutlinedInput-root fieldset,
.borderWhite label {
  color: white !important;
  border-color: white !important;
}

.dateTimeModalPicker {
  margin-top: 10px !important;
}


.borderWhite fieldset,
.borderWhite label,
.borderWhite input,
.borderYellow input {
  color: white !important;
  border-color: white !important;
}

.borderWhite fieldset,
.borderYellow fieldset {
  border-radius: 25px;
  border-width: 2px !important;
}

.borderYellow fieldset,
.borderYellow label {
  color: #C7D300 !important;
  border-color: #C7D300 !important;
}

.dateTimeModalPicker {
  width: 100%;
}

.dateTimeModalPicker .MuiOutlinedInput-input {
  padding: 18.5px 20px;
}

.dateTimeModalPicker input {
  font-size: 14px !important;
}

.dateTimeModalPicker .MuiFormHelperText-root {
  /*display: none;*/
}

.MuiPickersToolbar-toolbar {
  background-color: #C7D300 !important;
}

.MuiDialogContent-root h6,
.MuiDialogContent-root h4,
.MuiDialogContent-root button,
.MuiDialogContent-root span,
.MuiDialogContent-root div,
.MuiDialogActions-root span {
  color: #00374e !important;
}

.AFFFF:has(label.Mui-focused) {
  margin-top: 20px !important;
}



.msg2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.msg2 p {
  font-size: 14px;
}

.msg2 {
  width: 280px;
  height: 184px;
  padding: 6px 10px;
  margin: 10px;
  border-radius: 25px;
  position: relative;
  background: rgba(221, 221, 221, .3);
  font-size: 12px;
}

.msg2:after {
  content: "";
  position: absolute;
  border: 30px solid transparent;
  border-top: 30px solid rgba(221,221,221, .3);
  border-right: none;
  bottom: -60px;
  right: 22px;
}

.MuiRadio-root {
  padding: 0 !important;
}

.MuiFormControl-root.testC {
  margin-left: 10px !important;
  padding: 8px 0 5px;
}

.MuiFormControl-root.testC .MuiTypography-root {
  font-size: 12px;
  margin-left: 5px;
}

#step4 .MuiTypography-root {
  margin-top: 2px;
}

#step4 .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  fill: #ddd;
}

#step4 svg:nth-child(2):not(.not-yellow) {
  fill: #C7D300 !important;
}


.not-yellow .st0 {
  fill: #00374e !important;
}

.not-yellow-white .st0 {
  fill: white !important;
}

body.react-confirm-alert-body-element {
  overflow: unset !important;
}


.MuiChip-root {
  height: fit-content !important;
  text-align: left !important;
}


.MuiChip-label {
  overflow: unset !important;
  text-overflow: unset !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  white-space: pre-line !important;
  max-width: 100% !important;
  height: fit-content !important;
}


#stepper .MuiAutocomplete-clearIndicator {
  display: none;
}

.MuiSlider-root {
  padding: 5px 0 !important;
}

.ASDF .MuiInputBase-root {
  max-height: 41.13px;
}

.ASDF .MuiOutlinedInput-root {
  padding: 0 !important;
}

.ASDF input {
  max-width: 73%;
}

.customBullet {
  list-style: none;
  position: relative;
  line-height: 1.5em;
  font-family: VagRoundedBold;
  font-size: 12px;
}

.customBullet li:not(.customClearIcon):before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1em;
  content: '\25CF';
  font-size: 12px;
  margin: 0 1em 0 0;    /* any design */
  left: -0.2em;
}

.customBullet li:not(.customClearIcon) {
  padding-left: 1em;
}

.customBullet li.customClearIcon:before {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1em;
  content: '\2717';
  font-size: 12px;
  margin: 0 1em 0 0;    /* any design */
  left: -0.2em;
}

.customBullet li.customClearIcon {
  padding-left: 1em;
  color: #c7c4c482;
}



/* width */
.newAddressDiv {
  transition: background-color;
}

.overscroll-none {
  overscroll-behavior: contain;
}
.newAddressDiv ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.newAddressDiv ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
.newAddressDiv ::-webkit-scrollbar-thumb {
  background-color: #C7D300;
}

/* Handle on hover */
.newAddressDiv ::-webkit-scrollbar-thumb:hover {
  background-color: #adb800;
}

.newAddressDiv .testC .MuiFormControlLabel-root {
  vertical-align: top;
  align-items: flex-start;
  margin-right: 0;
}

.newAddressDiv .MuiTypography-root {
  width: 100%;
}
.emailRadio .MuiTypography-root {
  margin-left: 10px;
}

.defaultRadio .MuiTypography-root {
  margin-left: 10px;
  font-size: 12px;
}

.mainApp {
  margin: 0px;
  padding: 0px;
}

.mainApp a {
  text-decoration: none;
}

.mainApp button {
  cursor: pointer;
}

.mainApp input[type="search"] {
  outline: none;
}

.mainApp button, .mainApp input[type="text"] {
  /*border: none;*/
  /*outline: none;*/
}

/* ----------------- */
/* MAIN APP ELEMENTS */
/* ----------------- */

.mainApp .leftPanel {
  position: relative;
  display: inline-block;
  left: 0px;
  width: 320px;
  /*width: 400px;*/
  height: calc(100vh - 64px);
  float: left;
  background: #FFF;
  /*border-right: 1px solid #DDD;*/
}

.mainApp header {
  width: 100%;
  height: 110px;
  background: #FFF;
}

.mainApp .trigger {
  float: left;
  width: 32px;
  height: 32px;
  margin: 20px 15px;
  margin-bottom: 0px;
  color: #BBB;
  cursor: pointer;
  opacity: 0.6;
  background: none;
}

.mainApp .trigger:hover {
  opacity: 1;
}

.mainApp .trigger > svg {
  width: 24px;
  height: 24px;
  fill: #888;
}

.mainApp .searchChats {
  width: calc(100% - 85px);
  height: 40px;
  background: #EEE;
  float: left;
  padding: 8px;
  margin-top: 14px;
  border: 2px solid white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}

.mainApp .searchChats:focus {
  color: #444;
  border: 2px solid #419fd9;
  background: rgb(243, 243, 243);
}

.mainApp .chats {
  width: 100%;
  height: calc(100vh - 64px);
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 5px;
}

.mainApp .chatButton {
  float: left;
  width: calc(100% - 20px);
  min-height: 49px;
  margin: 2px 10px;
  color: #555;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 0.5);
  overflow: hidden;
}

.mainApp .chatButton:not(.activeChat) {
  background: rgba(221, 221, 221, 0.1);
}

.mainApp .chatButton:hover {
  color: #555;
  background: rgba(221, 221, 221, 0.3);
}

.mainApp .activeChat {
  color: #FFF;
  background: rgba(199, 211, 0, 0.1);
}

.mainApp .activeChat:hover {
  background: rgba(199, 211, 0, 0.3);
}

.mainApp .chatInfo {
  float: left;
  width: 100%;
  margin: 2px 10px;
  display: flex;
}

.mainApp .chatInfo p {
  float: left;
  font-family: VagRoundedBold, serif;
}

.mainApp .chatInfo .image {
  width: 55px;
  height: 55px;
  background: #DDD;
  background-size: cover;
  border-radius: 100%;
  margin: 13px;
}

.mainApp .chatInfo .name {
  float: left;
  font-size: 13px;
  font-weight: 600;
  color: #00374e;
  /*margin-left: 80px;*/
  margin-top: 0;
  margin-bottom: 0;
}

.mainApp .chatButton .chatInfo .name {

}

.mainApp .chatButton.activeChat .chatInfo .name {

}

.mainApp .chatInfo .message {
  float: left;
  clear: left;
  font-size: 14px;
}

.mainApp .chatButton .chatInfo .message {
  font-family: VagRoundedRegular, serif;
  color: grey;
  margin: 0;
}

.mainApp .chatButton:not(.activeChat).unread .chatInfo .message {
  font-family: VagRoundedBold, serif;
  color: #C7D300;
}

.mainApp .status {
  color: black;
  height: 100%;
  width: 100%;
  position: relative;
  float: right;
}

.mainApp .status .fixed {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: none;
}


.mainApp .status .count, .mainApp .count, #top-container .count {
  position: absolute;
  top: 25px;
  right: 0px;
  width: 20px;
  height: 20px;
  padding: 4px 4px 4px 4px;
  color: #00374e;
  background: #C7D300;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*box-shadow: 0 0 0 1px rgb(199 211 0 / 20%), 0 0 0 2px rgb(199 211 0 / 19%), 0 0 0 3px rgb(199 211 0 / 18%);*/
  filter: drop-shadow(0 0 1px #C7D300);
  margin: 1em;

}

.mainApp .status .date {
  position: absolute;
  right: 15px;
  top: 0;
  margin: 0;
  font-size: 12px;
}

.mainApp .chatButton.activeChat:not(.unread) .status .date,
.mainApp .chatButton:not(.unread) .status .date {
  font-weight: normal;
  font-family: VagRoundedRegular, serif;
}

.mainApp .status .read {
  position: absolute;
  right: 53px;
  top: 6px;
}

.mainApp .rightPanel {
  float: left;
  width: calc(100% - 321px);
  /*background: white;*/
  /*padding-top: 6px;*/
}

.mainApp .rightPanel .topBar {
  position: relative;
  width: 100%;
  height: 50px;
  background: #FFF;
}

.mainApp .leftSide {
  display: inline-block;
  clear: none;
  float: left;
}

.mainApp .leftSide .chatName, #top-container .chatName {
  float: left;
  font-family: VagRoundedBold, serif;
  margin-left: 30px;
  margin-top: 0px;
  margin-bottom: 0;
  color: #00374e;
  font-weight: 600;
  cursor: default;
}

.mainApp .chatName > span {
  font-size: 12px;
  font-weight: 500;
  color: #BBB;
  margin-left: 10px;
}

.mainApp .leftSide .chatStatus {
  float: left;
  clear: left;
  margin-left: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #aaa;
  font-weight: 300;
  cursor: default;
}

.mainApp .rightSide {
  display: inline-block;
  clear: none;
  float: right;
}

.mainApp .tbButton, .mainApp .otherTools .toolButtons {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background: #FFF;
  color: #888;
  border-radius: 100%;
}

.mainApp .tbButton:hover, .mainApp .otherTools .toolButtons:hover {
  color: #555;
  background: #DDD;
}


/* THE CONVERSATION HISTORY CSS */

.mainApp .messageHistory {
  float: left;
  position: relative;
  width: calc(100% - 70px);
  height: calc(100vh - 250px);
  padding: 10px;
  margin: 5px 25px;
  /*border: 1px solid #ddd;*/
  border-radius: 25px;
  /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
  box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  /*justify-content: flex-end;*/
}

.mainApp .userBg {
  /*background: url("https://images.unsplash.com/photo-1463058837219-41e13a132b7d?dpr=1&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=") 100% center;*/
}

.mainApp .msg {
  position: relative;
  width: auto;
  min-height: 40px;
  min-width: 100px;
  max-width: 45%;
  height: auto;
  padding: 10px;
  /*padding-bottom: 35px;*/
  margin: 0px 5px;
  margin-bottom: 0px;
  background: #FFF;
  border-radius: 7px;
  clear: both;
}

.mainApp .msg:nth-last-child(1) {
  margin-bottom: 20px;
}

.mainApp .msg .timestamp {
  display: block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #AAA;
  font-size: 10px;
  user-select: none;
}

.mainApp .messageReceived {
  float: left;
  text-align: left;
  background: rgba(199, 211, 0, 0.3);
}

.mainApp .messageSent {
  float: right;
  text-align: left;
  background: rgba(221, 221, 221, 0.6);
}

/*.mainApp .messageSent > .timestamp, .mainApp .messageSent > .readStatus {*/
/*    bottom: 10px;*/
/*    right: 10px;*/
/*    color: darkgreen;*/
/*    user-select: none;*/
/*}*/

.mainApp .messageSent > .readStatus {
  position: absolute;
  bottom: 10px;
  right: 12px;
}


/* THE REPLY BAR CSS */

.mainApp .replyBar {
  width: 100%;
  height: 70px;
  float: left;
  background: #fff;
}

.mainApp .replyBar .attach {
  width: 70px;
  height: 70px;
  color: #777;
  background: #FFF;
  float: left;
}

.mainApp .replyBar .attach:hover {
  color: #555;
  background: #DDD;
}

.mainApp .replyBar .d45 {
  transform: rotate(45deg);
  font-size: 32px;
}

.mainApp .replyBar .replyMessage {
  width: calc(100% - 220px);
  float: left;
  height: 70px;
  padding: 0px 15px;
  font-size: 16px;
}

.mainApp .replyBar .otherTools {
  float: right;
  margin-right: 10px;
  padding-top: 5px;
  padding-left: 4px;
}

.mainApp .replyBar #inputWrapper .otherTools {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  top: 0;
}

.mainApp #inputWrapper {
  position: relative;
  padding: 15px 20px;
  font-family: VagRoundedRegular, serif;
  text-align: left;
}

.mainApp #input {
  background-color: white;
  border: none;
  border-radius: 50px;
  font-family: inherit;
  font-size: inherit;
  height: 40px;
  outline: none;
  padding-inline-end: 40px;
  padding-inline-start: 52px;
  position: relative;
  width: calc(100% - 35px);
  /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
  box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
  color: black;
  padding-top: 4px;
}

.mainApp #input2 {
  background-color: white;
  border: none;
  border-radius: 50px;
  font-family: inherit;
  font-size: inherit;
  height: 40px;
  outline: none;
  padding-inline-end: 40px;
  padding-inline-start: 20px;
  position: relative;
  width: calc(100% - 35px);
  /*box-shadow:  0 1px 6px 0 rgba(32, 33, 36, .28);*/
  box-shadow: 0 0 2px 0 rgba(32, 33, 36, .28);
  color: black;
  padding-top: 4px;
}

.mainApp #icon {
  height: 100%;
  left: 35px;
  position: absolute;
  top: 0;
  color: #00374e;
}

.mainApp #icon2 {
  padding: 9px 2px 9px 0px;
  border-radius: 25px;
  cursor: pointer;
  height: 14px;
  width: 32px;
  position: absolute;
  right: 18px;
  top: 20px;
  color: #00374e;
}

.mainApp #icon3 {
  padding: 5px;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #C7D300;
}

.mainApp #icon3 .cls-1 {
  fill: #C7D300;
}

.mainApp #icon2:hover {
  background: rgba(221, 221, 221, 0.6);
}

.mainApp [type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  right: 20px;
  height: 10px;
  width: 10px;
  color: #00374e;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMTIzLjA1cHgiIGhlaWdodD0iMTIzLjA1cHgiIHZpZXdCb3g9IjAgMCAxMjMuMDUgMTIzLjA1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjMuMDUgMTIzLjA1OyIKCSB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBmaWxsPSIjMDAzNzRlIiBkPSJNMTIxLjMyNSwxMC45MjVsLTguNS04LjM5OWMtMi4zLTIuMy02LjEtMi4zLTguNSwwbC00Mi40LDQyLjM5OUwxOC43MjYsMS43MjZjLTIuMzAxLTIuMzAxLTYuMTAxLTIuMzAxLTguNSwwbC04LjUsOC41CgkJYy0yLjMwMSwyLjMtMi4zMDEsNi4xLDAsOC41bDQzLjEsNDMuMWwtNDIuMyw0Mi41Yy0yLjMsMi4zLTIuMyw2LjEsMCw4LjVsOC41LDguNWMyLjMsMi4zLDYuMSwyLjMsOC41LDBsNDIuMzk5LTQyLjRsNDIuNCw0Mi40CgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPg==);
  background-size: 10px 10px;
  cursor: pointer;
}

.mainApp .expandIcon {
  position: absolute;
  right: 10px;
  top: 15px;
}

.mainApp .chatInfo .wrapper {
  /*width: calc(100% - 20px);*/
  width: calc(150%);
}

.mainApp .headerName, #top-container .headerName {
  text-align: left;
  font-family: VagRoundedBold, serif;
  font-size: 25px;
  color: #00374e;
  margin-top: 0;
  margin-left: 5px;
  margin-bottom: 0;
}

.mainApp .messageHistory .messageSenderName {
  /*position: absolute;*/
  /*top: 10px;*/
  /*left: 10px;*/
  color: #00374e;
  font-family: VagRoundedBold, serif;
}

.mainApp .messageHistory .message {
  /*position: absolute;*/
  /*top: 25px;*/
  /*left: 10px;*/
  word-break: break-all;
  padding-top: 5px;
  font-size: 14px;
}


.mainApp .messageHistory .message:after {
  content:"";
  display:inline-block;
  height:2px;
  width:35px; /* the value of padding */
  /*background:red; !* to illustrate *!*/
}

.filePickerLabel {
  border-radius: 25px;
  padding: 5px 15px;
  text-transform: unset;
  background-color: #C7D300;
  color: #00374e;
  font-size: 13px;
  font-family: VagRoundedBold;
  border: 2px solid #C7D300;
  transition: all 0.2s ease;
}

.filePickerLabel:hover {
  background-color: transparent;
  border: 2px solid #C7D300;
  color: #C7D300;
}
